<template>
    <div>
        <div class="d-flex px-3 small-text">
            <span>Responsible User:</span>
            <span class="ms-auto">{{ item.owner_user_name }}</span>
        </div>
        <div class="d-flex px-3 small-text">
            <span>Priority:</span>
            <span class="ms-auto" :class="getPriorityClass ">{{ getPriorityText }}</span>
        </div>
        <div class="d-flex px-3 small-text">
            <span>Duration:</span>
            <span class="ms-auto">
                <span>{{ getDateFormat.start }}</span>
                <span class="text-grey"> to </span>
                <span>{{ getDateFormat.end }}</span>
            </span>
        </div>
    </div>
    <!-- <div>
        <div :class="current_item_is_selected ? 'text-dark' : ''" class="mt-auto ps-2 pe-2 font-size-10" >
            <div class="mb-1" v-if="item.owner_user_name">
                <span class="grey-date-tag">
                    Owner: 
                    {{ item.owner_user_name }}
                </span>
            </div><div class="mb-1" v-if="item.responsible_user_name">
                <span class="grey-date-tag">
                    Responsible User: 
                    {{ item.responsible_user_name }}
                </span>
            </div>
            <div class="mb-1">
                <span class="grey-date-tag">
                    <font-awesome-icon class="fa-fw" icon="fa-calendar-check"></font-awesome-icon>
                    {{ getDateFormat }}
                </span>
            </div>
            <div class="mb-1">
                <span class="grey-date-tag text-capitalize" :class="priorityColor">
                    {{getPriority}}
                </span>
            </div>
        </div>
        <div class="mb-1 mt-0"/>
    </div> -->
</template>

<script>
import { format, parseISO } from 'date-fns';
export default {
    data:() => ({}),
    props:{
        item:{
            required: true,
        },
        current_item_is_selected:{
            default: false,
        }
    },
    computed:{
        getPriorityText(){
            if(this.item.priority == null){
                return 'No priority set';
            }
            else{
                return this.item.priority;
            }
        },
        getPriorityClass(){
            switch(this.item.priority){
                case 'medium':{
                    return 'badge-soft-primary'
                }
                case 'high':{
                    return 'badge-soft-danger'
                }
                case 'low':{
                    return 'badge-soft-success'
                }
                default:{ return '' }
            }
        },
        getDateFormat(){
            const start = format(parseISO(this.item.start_date), 'dd MMM yyyy');
            const end = format(parseISO(this.item.end_date), 'dd MMM yyyy');
            return {
                start: start,
                end: end,
            }
            // let start = this.getSimpleDate(this.item.start_date, '/');
            // //! do some hack to get the end date.. some items have deadline, some items have end_date
            // let end = this.item.deadline ? this.item.deadline : this.item.end_date
            // end = this.getSimpleDate(end, '/');
            // return `${start} - ${end}`
        },
    },
    methods:{},
    mounted(){},
}
</script>

<style>

</style>